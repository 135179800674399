/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "../../node_modules/@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "../../node_modules/@ionic/angular/css/normalize.css";
@import "../../node_modules/@ionic/angular/css/structure.css";
@import "../../node_modules/@ionic/angular/css/typography.css";
@import '../../node_modules/@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "../../node_modules/@ionic/angular/css/padding.css";
@import "../../node_modules/@ionic/angular/css/float-elements.css";
@import "../../node_modules/@ionic/angular/css/text-alignment.css";
@import "../../node_modules/@ionic/angular/css/text-transformation.css";
@import "../../node_modules/@ionic/angular/css/flex-utils.css";

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

/* Mixins */
@import "mixins";

:root {
  color-scheme: light only !important;
}

html {
  display: flex;
  justify-content: center;
}

body {
  color: var(--ion-text-color);
  background-color: var(--ion-background-color);
  font-family: 'Inter', sans-serif;
  max-width: 500px;
}

.mainBody {
  background-color: var(--bgcolor--primary);
}


.marginL1 {
  margin: $spaceL1;
}

.marginL2 {
  margin: $spaceL2;
}

.marginL3 {
  margin: $spaceL3;
}

.marginL4 {
  margin: $spaceL4;
}

.marginTopL1 {
  margin-top: $spaceL1;
}

.marginTopL2 {
  margin-top: $spaceL2;
}

.marginTopL3 {
  margin-top: $spaceL3;
}

.marginTopL4 {
  margin-top: $spaceL4;
}

.marginTopL5 {
  margin-top: $spaceL5;
}

.marginTopL6 {
  margin-top: $spaceL6;
}

.marginTopL7 {
  margin-top: $spaceL7;
}

.marginTopL8 {
  margin-top: $spaceL8;
}

.marginBottomL1 {
  margin-bottom: $spaceL1;
}

.marginBottomL2 {
  margin-bottom: $spaceL2;
}

.marginBottomL3 {
  margin-bottom: $spaceL3;
}

.marginBottomL4 {
  margin-bottom: $spaceL4;
}

.marginBottomL5 {
  margin-bottom: $spaceL5;
}

.marginBottomL6 {
  margin-bottom: $spaceL6;
}

.marginLeftL1 {
  margin-left: $spaceL1;
}

.marginLeftL2 {
  margin-left: $spaceL2;
}

.marginLeftL3 {
  margin-left: $spaceL3;
}

.marginLeftL4 {
  margin-right: $spaceL4;
}

.marginRightL1 {
  margin-right: $spaceL1;
}

.marginRightL2 {
  margin-right: $spaceL2;
}

.marginRightL3 {
  margin-right: $spaceL3;
}

.marginRightL4 {
  margin-right: $spaceL4;
}

.noPad {
  padding: 0 !important;
  --padding-top: 0px !important;
  --padding-start: 0px !important;
  --padding-right: 0px !important;
  --padding-end: 0px !important;
}

.padL1 {
  padding: $spaceL1;
}

.padL2 {
  padding: $spaceL2;
}

.padL3 {
  padding: $spaceL3;
}

.padL4 {
  padding: $spaceL4;
}

.padLeftL1 {
  padding-left: $spaceL1;
}

.padLeftL2 {
  padding-left: $spaceL2;
}

.padLeftL3 {
  padding-left: $spaceL3;
}

.padLeftL4 {
  padding-left: $spaceL4;
}

.padRightL1 {
  padding-right: $spaceL1;
}

.padRightL2 {
  padding-right: $spaceL2;
}

.padRightL3 {
  padding-right: $spaceL3;
}

.padRightL4 {
  padding-right: $spaceL4;
}

.padTopL1 {
  padding-top: $spaceL1;
}

.padTopL2 {
  padding-top: $spaceL2;
}

.padTopL3 {
  padding-top: $spaceL3;
}

.padTopL4 {
  padding-top: $spaceL4;
}

.padTopL5 {
  padding-top: $spaceL5;
}

.padTopL6 {
  padding-top: $spaceL6;
}

.padBottomL1 {
  padding-bottom: $spaceL1;
}

.padBottomL2 {
  padding-bottom: $spaceL2;
}

.padBottomL3 {
  padding-bottom: $spaceL3;
}

.padBottomL4 {
  padding-bottom: $spaceL4;
}

.padBottomL5 {
  padding-bottom: $spaceL5;
}

.padBottomL6 {
  padding-bottom: $spaceL6;
}

.padBottomL7 {
  padding-bottom: $spaceL7;
}

.padBottomL8 {
  padding-bottom: $spaceL8;
}


.fontSize10 {
  font-size: 10px;
}

.fontSize11 {
  font-size: 11px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize13 {
  font-size: 13px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize32 {
  font-size: 32px;
}

.fontWeight--medium {
  font-weight: 600;
}

.fontWeight--bold {
  font-weight: 700;
}


.w-100 {
  width: 100%;
}


.flex--1 {
  flex: 1;
}

.flex--2 {
  flex: 2;
}

.flex--3 {
  flex: 3;
}

.ion--flex {
  display: flex;
}

.flexFlow--wrap {
  flex-flow: wrap;
}

.flex--center--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex__flow--column {
  flex-flow: column;
}

.pointer {
  cursor: pointer;
}

.textAlign--center {
  text-align: center;
}

.textAlign--left {
  text-align: left;
}

.textAlign--right {
  text-align: right;
}


.pageHeading {
  font-weight: 700;
  font-size: 24px;
}

.sectionHeading {
  font-weight: 700;
  font-size: 20px;
}

.sectionSubHeading {
  font-weight: 700;
  font-size: 16px;
}


.textColor--primary {
  color: var(--textcolor--primary);
}

.textColor--primary2 {
  color: var(--textcolor--primary2);
}

.textColor--secondary {
  color: var(--textcolor--secondary);
}

.textColor--winning {
  color: var(--textcolor--winning)
}

.textColor--startTime {
  color: var(--textcolor--startTime);
}

.textColor--error {
  color: var(--textcolor--error);
}

.textColor--live {
  color: var(--color-live);
}

.textColor--primaryHighlight {
  color: var(--textcolor--primaryHighlight);
}

.bgColor--primary {
  background-color: var(--bgcolor--primary);
}

.bgColor--primary--lighter {
  background-color: var(--bgcolor--primary--lighter);
}

.bgColor--secondary {
  background-color: var(--bgcolor--secondary);
}

.bgColor--highlight {
  background-color: var(--bgcolor--highlight);
}

.position--relative {
  position: relative;
}

.cta {
  position: relative;
  padding: $spaceL3 $spaceL4;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  overflow: hidden;
  text-decoration: none;

  &.cta--small {
    height: 35px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cta--primary {
  @extend .cta;
  color: var(--textcolor--primary);
  background: var(--bgcolor--primaryCta);
}

.cta--danger {
  @extend .cta;
  background-color: transparent;
  color: var(--bgcolor--primaryCta);
  border: 1px solid var(--bgcolor--primaryCta);
}

.cta--highlight {
  @extend .cta;
  color: var(--bgcolor--primary);
  background: var(--bgcolor--highlight);
}

.cta--secondary {
  @extend .cta;
  color: var(--textcolor--primary);
  background-color: var(--bgcolor--secondary);
}


.customBottomSheet {
  --border-radius: 16px 16px 0 0;
  --ion-backdrop-color: rgba(255, 255, 255, 0.3);
  --backdrop-opacity: 1;
}

.loginModal {
  --width: fit-content;
  --min-width: 320px;
  --height: fit-content;
  --border-radius: 16px;
  --ion-backdrop-color: rgba(255, 255, 255, 0.3);
  --backdrop-opacity: 1;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.customInput {
  width: 100%;
  background: var(--bgcolor--secondary);
  border-radius: 5px;
}


.contestPreviewCanvas {
  width: 315px;
  height: 315px;
  background: linear-gradient(180deg, #184066 0%, #1C1454 100%);
}

.bsOverlay {
  z-index: 9999999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 29, 29, 0.9);;

  .bsWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $spaceL3 $spaceL3 0 0;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    background: linear-gradient(180deg, #184066 0%, #1C1454 100%);
    min-height: 150px;
    transition: 500ms;
  }
}

.ion-content-scroll-host {
  min-height: 100%;
}


.oneLineText {
  word-break: break-all;
  @include limitNumberOfLinesText(1);
}

.disabled {
  opacity: 0.6;
}

.display--block {
  display: block;
}

.order--1 {
  order: 1;
}

.order--2 {
  order: 2;
}

ion-action-sheet {
  .action-sheet-group {
    background: rgba(255, 255, 255, 0.9) !important;
  }

  .action-sheet-cancel {
    background: var(--textcolor--primary) !important;
  }

  .action-sheet-button,
  .action-sheet-button.ion-focused {
    color: #000000 !important;
  }

  ion-backdrop {
    opacity: 0.6 !important;
  }
}

.standardPageHeader {
  position: relative;
  z-index: 999999999;
  background: var(--bgcolor--primary);

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 54.17%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;
  }
}

.toolbar-background,
.header-background {
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
  background: transparent !important;
  display: none !important;
}

ion-toolbar {
  --padding-top: 0px !important;
  --padding-start: 0px !important;
  --padding-right: 0px !important;
  --padding-end: 0px !important;
}

ion-modal {
  &.center {
    --width: calc(100% - 2em);
    align-items: center;
    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }

  &.bottom-end {
    align-items: flex-end;
    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }
}

.tourWidget {
  position: relative;
  z-index: 2;
  padding: $spaceL3;
  color: var(--bgcolor--primary);
  background-color: #EF9002;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;

  &:after {
    position: absolute;
    content: ' ';
    right: 25px;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #EF9002;
  }
}

.hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
