@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qvxkdk');
  src:  url('fonts/icomoon.eot?qvxkdk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qvxkdk') format('truetype'),
    url('fonts/icomoon.woff?qvxkdk') format('woff'),
    url('fonts/icomoon.svg?qvxkdk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-leaderboard:before {
  content: "\e916";
}
.icon-trash:before {
  content: "\e913";
}
.icon-flag:before {
  content: "\e910";
}
.icon-winning:before {
  content: "\e911";
}
.icon-score:before {
  content: "\e912";
}
.icon-check-selection:before {
  content: "\e90f";
}
.icon-minus:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-label:before {
  content: "\e90c";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-help:before {
  content: "\e901";
}
.icon-cross:before {
  content: "\e902";
}
.icon-announcement:before {
  content: "\e903";
}
.icon-filter:before {
  content: "\e904";
}
.icon-add:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-home:before {
  content: "\e907";
}
.icon-profile:before {
  content: "\e908";
}
.icon-prize-pool:before {
  content: "\e909";
}
.icon-trophy:before {
  content: "\e90a";
}
.icon-participation:before {
  content: "\e90b";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-share:before {
  content: "\ea82";
}
.icon-settings:before {
  content: "\e994";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-edit:before {
  content: "\e914";
}
.icon-chevron-right:before {
  content: "\e915";
}
