$spaceL1: 4px;
$spaceL2: 8px;
$spaceL3: 16px;
$spaceL4: 24px;
$spaceL5: 32px;
$spaceL6: 40px;
$spaceL7: 48px;
$spaceL8: 56px;

$padL1: $spaceL1;
$padL2: $spaceL2;
$padL3: $spaceL3;
$padL4: $spaceL4;

$padXL1: $spaceL1;
$padXL2: $spaceL2;
$padL3: $spaceL3;
$padL4: $spaceL4;

@mixin limitNumberOfLinesText($num:1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
}
